import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination, EffectFade, Controller, Thumbs } from 'swiper/modules';
import 'swiper/css/bundle';

document.addEventListener('DOMContentLoaded', function () {
const checkInterval = setInterval(() => {
    const swiperContainer = document.querySelector(".infamous-critters__slider_preview");

    if (swiperContainer !== null) {
      clearInterval(checkInterval); // stop when found
      initInfamosSlider(); // init swiper
    }
  }, 100); 
})

export function initInfamosSlider() {
  const swiperContainer = document.querySelector(".infamous-critters__slider_preview");
  const swiperThumbsContainer = document.querySelector(".infamous-critters__slider_thumbs");
  let topSlider = null;
  let thumbsSlider = null;

  if (swiperContainer !== null) {
    const params = {
      modules: [Navigation, Pagination, Autoplay, EffectFade, Controller, Thumbs],
      slidesPerView: 1,
      freeMode: false,
      loop: false,
      effect: "fade",
      pagination: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };

    topSlider = new Swiper(swiperContainer, params);

    if (swiperThumbsContainer !== null && topSlider) {
      const thumbsParams = {
        modules: [Navigation, Pagination, Autoplay, EffectFade, Controller],
        slidesPerView: 6,
        spaceBetween: 10,
        loop: false,
        slideToClickedSlide: true,
        pagination: false,
        navigation: false,
        breakpoints: {
          320: {
            slidesPerView: 2.3,
          },
          630: {
            slidesPerView: 3.3,
          },
          768: {
            slidesPerView: 4.3,
          },
          992: {
            slidesPerView: 4.5,
          },
          1600: {
            slidesPerView: 6,
            spaceBetween: 35,
          },
        },
      };

      thumbsSlider = new Swiper(swiperThumbsContainer, thumbsParams);

      topSlider.controller.control = thumbsSlider;
      thumbsSlider.controller.control = topSlider;

      thumbsSlider.on('click', function () {
        const { clickedIndex } = this;

        thumbsSlider.slideTo(clickedIndex);
        topSlider.slideTo(clickedIndex);
      });

      topSlider.on('slideChangeTransitionEnd', function () {
        setActiveSlide()
      });
    }
  }
}

function setActiveSlide() {
  setTimeout(() => {
    const topActive = document.querySelector('.infamous-critters__slider_preview-slide.swiper-slide-active')
    const topActiveIndex = topActive.getAttribute('data-index')

    document.querySelectorAll('.infamous-critters__slider_thumbs-slide').forEach(item => {
      item.classList.remove('active')

      const thumbsIndex = item.getAttribute('data-index')

      if(topActiveIndex == thumbsIndex) {
        item.classList.add('active')
      } 
    }); 
  }, 50)
}
